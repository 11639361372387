import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const ButtonExternal = makeShortcode("ButtonExternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "château-de-morey-charme--prestige-de-france"
    }}><strong parentName="h1">{`Château de Morey Charme & Prestige de France`}</strong></h1>
    <h2 {...{
      "id": "eine-geschichte-und-eine-zukunft"
    }}>{`Eine Geschichte und eine Zukunft`}</h2>
    <h3 {...{
      "id": "schnelle-präsentation"
    }}>{`Schnelle Präsentation`}</h3>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": "100%",
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/h0Wnp2wRXgw?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <p>{`Das in Lothringen gelegene Château de Morey empfängt Sie seit 1998 in diesen Mauern.`}</p>
    <p>{`Zwischen Nancy und Metz können Sie sich in einem seiner 5 geräumigen Gästezimmer entspannen, begleitet von seinem bukolischen Park und seinen Aktivitäten (Minigolf, Schwimmbad, SPA, Reitsportaktivitäten, Tischfußball, Billard, Boule).`}</p>
    <p>{`Ein außergewöhnlicher Zwischenstopp, das Schloss aus dem 16. Jahrhundert, ehemalige Residenz der Herzöge von Lothringen, leicht zugänglich (5 km von der A31 an der D44a). Isoliert von der Stadt und doch nur 20 Minuten von Nancy und 10 Minuten von mehreren lokalen Restaurants entfernt, genießen Sie einen fabelhaften Moment der Süße in einer gewissen Ruhe.`}</p>
    <p>{`Das Château liegt genauer gesagt in der Gemeinde Belleau auf 305 m Höhe, die einen atemberaubenden Blick auf das Natagne-Tal bietet, das den Charme Lothringens ausstrahlt.`}</p>
    <p>{`Sie können Ihre Empfänge auch in einer Residenz organisieren, die seit etwa zwanzig Jahren Hochzeiten, Seminare und andere Veranstaltungen ausrichtet.`}</p>
    <p>{`Heute ist es die Priorität des Château de Morey, Sie innerhalb dieser Mauern zu empfangen, damit Sie einen Moment des Staunens verbringen können. Ein Ort, der Ruhe und Charme vereint und Ihnen einen Aufenthalt bietet, bei dem Sie neue Energie tanken können.`}</p>
    <ButtonInternal to="/de/bed-and-breakfast/" mdxType="ButtonInternal">Entdecken Sie unsere Gästezimmer</ButtonInternal>
    <ButtonInternal to="/de/halls/" mdxType="ButtonInternal">Entdecken Sie unsere Zimmervermietungen</ButtonInternal>
    <h2 {...{
      "id": "its-history"
    }}>{`Its history`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/28e4fc40eb91a87992ead592da6b2869/1fe05/chateau_de_morey.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAVCzok8mP//EABkQAAIDAQAAAAAAAAAAAAAAAAABAgMSEf/aAAgBAQABBQJ2T1bLQojno0ucrP/EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPwGhf//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwGtf//EABwQAAIBBQEAAAAAAAAAAAAAAAABIQIRMTNBof/aAAgBAQAGPwLKLs4SkTSjX6f/xAAbEAEAAwEAAwAAAAAAAAAAAAABABFRMSFBkf/aAAgBAQABPyG+Goclx9nSIl+GWxR0bLKLPlwfE//aAAwDAQACAAMAAAAQy+//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8Qo2P/xAAXEQADAQAAAAAAAAAAAAAAAAAAASER/9oACAECAQE/EKVNH//EABwQAAICAgMAAAAAAAAAAAAAAAERACExQXGBof/aAAgBAQABPxBk7FCmri14U7qA5AJsElS78LCFj2JN9APsRgdtwKf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/28e4fc40eb91a87992ead592da6b2869/f994e/chateau_de_morey.webp 163w", "/static/28e4fc40eb91a87992ead592da6b2869/bcd70/chateau_de_morey.webp 325w", "/static/28e4fc40eb91a87992ead592da6b2869/5d8d7/chateau_de_morey.webp 650w", "/static/28e4fc40eb91a87992ead592da6b2869/6c7d1/chateau_de_morey.webp 960w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/28e4fc40eb91a87992ead592da6b2869/367e5/chateau_de_morey.jpg 163w", "/static/28e4fc40eb91a87992ead592da6b2869/ab07c/chateau_de_morey.jpg 325w", "/static/28e4fc40eb91a87992ead592da6b2869/ebabe/chateau_de_morey.jpg 650w", "/static/28e4fc40eb91a87992ead592da6b2869/1fe05/chateau_de_morey.jpg 960w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/28e4fc40eb91a87992ead592da6b2869/ebabe/chateau_de_morey.jpg",
              "alt": "Château de Morey",
              "title": "Château de Morey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Château de Morey`}</strong></p>
    <p>{`Im Lothringen des 12. Jahrhunderts soll auf den Ruinen eines Templerklosters eine Burg errichtet worden sein. Es soll die Residenz des Herrschers von Morey Jean de Toulon gewesen sein, als der Besitz die Burg, den Bauernhof und die romanische Kirche des 12. Es war dann die Familie Joly, die die drei Güter trennte, und der Schriftsteller Ducrocq, der ihre Nachfolge antrat.`}</p>
    <p>{`Opfer eines Brandes im Jahr 1985 wurde die Burg, nachdem sie 8 Jahre lang verlassen war, von der Familie Karst, die heute die kommerzielle Tätigkeit der Burg betreibt, renoviert.`}</p>
    <p>{`Entdecken Sie im Wald oberhalb des Schlosses die Spuren der Geschichte. Gehen Sie zu Fuß, mit dem Fahrrad oder zu Pferd die Waldwege entlang, um alte Kriegsschützengräben, geheime Galerien und mehr zu sehen…`}</p>
    <p>{`Die Geschichte des Château de Morey erstreckt sich von nah und fern. Und ja, Lothringen ist eine Region mit einer schweren Vergangenheit. Sie hat viele Kriege, Besetzungen und geheimnisvolle Geschichten erlebt. Vom Mittelalter bis in die Gegenwart, durch viele Epochen hindurch, können Sie die Geheimnisse dieser Region entdecken.`}</p>
    <p>{`Im Herzen der Meurthe et Moselle neigt die Burg dazu, weiterhin Früchte aus ihrer Geschichte zu tragen, sie begleitet Sie in Ihren intimen Momenten, feiert Ihre Hochzeiten, begleitet Ihre Abende… Ein riesiger Garten, der von einer mehr als 5 Meter hohen Mauer gestützt wird. Kastanienbäume, libanesische Zeder, Linden, Purpurbuchen, Eichen, Ahorn und ein Ententeich schaffen einen einzigartigen Raum im Château de Morey.`}</p>
    <ButtonInternal to="/en/newsletter/" mdxType="ButtonInternal">Dem Newsletter beitreten</ButtonInternal>
    <h2 {...{
      "id": "sein-aktuelles-leben"
    }}>{`Sein aktuelles Leben`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e5f290bd73f3916fcf58131988aa1728/a4af9/tourelle2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABd2IxndIjg//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhESIf/aAAgBAQABBQLqGZ5pD0bo2j//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPwG4Y//EABsQAAEEAwAAAAAAAAAAAAAAAAABEBEhEjFh/9oACAEBAAY/AppekvOS2bP/xAAbEAADAQADAQAAAAAAAAAAAAAAAREhMUFh4f/aAAgBAQABPyHCr6vBrb6OGWLJHBFzZhqfM//aAAwDAQACAAMAAAAQZw//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQMBAT8QEmW3/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/EEHY/8QAHhABAAICAgMBAAAAAAAAAAAAAQARIUExUWFxobH/2gAIAQEAAT8QSiAtifqNRCjPPbfcuC78m4SwBgOT3mMgMg0AcfYwDun/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e5f290bd73f3916fcf58131988aa1728/f994e/tourelle2.webp 163w", "/static/e5f290bd73f3916fcf58131988aa1728/bcd70/tourelle2.webp 325w", "/static/e5f290bd73f3916fcf58131988aa1728/5d8d7/tourelle2.webp 650w", "/static/e5f290bd73f3916fcf58131988aa1728/c4443/tourelle2.webp 975w", "/static/e5f290bd73f3916fcf58131988aa1728/5cc32/tourelle2.webp 1300w", "/static/e5f290bd73f3916fcf58131988aa1728/1dd8b/tourelle2.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e5f290bd73f3916fcf58131988aa1728/367e5/tourelle2.jpg 163w", "/static/e5f290bd73f3916fcf58131988aa1728/ab07c/tourelle2.jpg 325w", "/static/e5f290bd73f3916fcf58131988aa1728/ebabe/tourelle2.jpg 650w", "/static/e5f290bd73f3916fcf58131988aa1728/cdb69/tourelle2.jpg 975w", "/static/e5f290bd73f3916fcf58131988aa1728/2616f/tourelle2.jpg 1300w", "/static/e5f290bd73f3916fcf58131988aa1728/a4af9/tourelle2.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/e5f290bd73f3916fcf58131988aa1728/ebabe/tourelle2.jpg",
              "alt": "Château de Morey",
              "title": "Château de Morey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Château de Morey`}</strong></p>
    <p>{`Das Château de Morey heißt Sie auch heute noch in seinen Räumlichkeiten willkommen und tut alles, damit Sie eine unvergessliche Zeit erleben. Vom Empfang bis zu den Dienstleistungen, erleben Sie einen authentischen und spektakulären Aufenthalt in den Steinmauern der Residenz.`}</p>
    <p>{`Die Domäne ist sowohl ein Ort der Erholung als auch ein festlicher Ort. Neben dem Empfang Ihrer Veranstaltungen organisieren wir regelmässig Abende oder Brunches, um mit Ihnen schöne Stunden zu verbringen.`}</p>
    <p>{`Das Château wird ständig verbessert und plant, sein Dach neu zu decken. Zu diesem Zweck wurde ein Kätzchen geschaffen, um dies zu ermöglichen.`}</p>
    <ButtonExternal href="https://www.leetchi.com/c/projets-de-chateau-de-morey-22970336" mdxType="ButtonExternal">Mitmachen</ButtonExternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      